import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="PROSITOS app" />

    <div id="wrapper">
      <h1>PROSITOS app</h1>
      <div style={{ textAlign: 'center' }}>
        Mobile App for flight information and ticket purchase
      </div>
    </div>
    <div style={{ background: '#E2E2E2', padding: '4em 0' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'flex-start', maxWidth: 800, margin: 'auto' }}>
        <img src="/images/prositos-app-1.png" alt="Screenshot" style={{ width: '50%', padding: 20 }} />
        <img src="/images/prositos-app-2.png" alt="Screenshot" style={{ width: '50%', padding: 20 }} />
      </div>
      <div style={{ fontSize: '2em', marginTop: '1em', textAlign: 'center' }}><a href="http://prositos.no/pda/" target="_blank" rel="noopener noreferrer">prositos.no/pda/</a></div>
    </div>
  </Layout>
);
